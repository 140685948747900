import { post, patch, get } from "../api";

export async function getUserInfo(user_pk) {
  let response = await get(`account/users/${user_pk}/`);
  return response;
}

export async function getCareReceiver(care_receiver_pk) {
  let response = await get(`account/care-receivers/${care_receiver_pk}/`);
  return response;
}

export async function getAccountInfo(user_pk) {
  let response = await get(`account/users/${user_pk}/account/`);
  return response;
}

export async function getUserLifecycle(user_pk) {
  let response = await get(`account/users/${user_pk}/lifecycle/`);
  return response;
}

export async function signedLatestTerms(user_pk) {
  let response = await get(`account/users/${user_pk}/signed-latest-terms/`);
  return response.signed_terms;
}

export async function postTermsAndConditionsUpdate(
  user_pk,
  terms_and_conditions_pk,
) {
  let response = await post(
    `account/users/${user_pk}/update-terms-and-conditions/`,
    {
      terms_and_conditions_pk: terms_and_conditions_pk,
    },
  );
}

export async function patchUserData(user_pk, user_data) {
  // strip any null fields:
  Object.keys(user_data).forEach(
    (key) => user_data[key] === null && delete user_data[key],
  );

  let response = await patch(`account/users/${user_pk}/`, user_data);
  return response;
}

export async function patchCareReceiverData(
  care_receiver_pk,
  care_receiver_data,
) {
  // strip any null fields:
  Object.keys(care_receiver_data).forEach(
    (key) => care_receiver_data[key] === null && delete care_receiver_data[key],
  );

  let response = await patch(
    `account/care-receivers/${care_receiver_pk}/details/`,
    care_receiver_data,
  );
  return response;
}

export async function patchUserProfile(user_pk, user_data) {
  // strip any null fields:
  Object.keys(user_data).forEach(
    (key) => user_data[key] === null && delete user_data[key],
  );

  let response = await patch(`account/users/${user_pk}/profile/`, user_data);
  return response;
}

export async function patchCareReceiverProfile(
  care_receiver_pk,
  care_receiver_data,
) {
  // strip any null fields:
  Object.keys(care_receiver_data).forEach(
    (key) => care_receiver_data[key] === null && delete care_receiver_data[key],
  );

  let response = await patch(
    `account/care-receivers/${care_receiver_pk}/details/`,
    { profile: care_receiver_data },
  );
  return response;
}

export async function patchCareReceiverRelationship(
  care_receiver_pk,
  care_receiver_data,
) {
  // strip any null fields:
  Object.keys(care_receiver_data).forEach(
    (key) => care_receiver_data[key] === null && delete care_receiver_data[key],
  );

  let response = await patch(
    `account/care-receivers/${care_receiver_pk}/details/`,
    { relationship: care_receiver_data },
  );
  return response;
}

export async function patchProfileAddress(address_pk, address_data) {
  let response = await patch(
    `account/mailing-addresses/${address_pk}/`,
    address_data,
  );
  return response;
}

export async function postProfileAddress(address_data) {
  let response = await post(`account/mailing-addresses/`, address_data);
  return response;
}

export async function postOtherProfilePhone(user_pk, phone_data) {
  let response = await post(
    `account/users/${user_pk}/phone-numbers/`,
    phone_data,
  );
  return response;
}

export async function postOtherProfileEmail(user_pk, email_data) {
  let response = await post(`account/users/${user_pk}/emails/`, email_data);
  return response;
}

export async function patchUserLifecycle(user_pk, lifecycle_data) {
  let response = await patch(
    `account/users/${user_pk}/lifecycle/`,
    lifecycle_data,
  );
  return response;
}

export async function postNewCareReceiver(care_receiver_data) {
  let response = await post(`account/care-receivers/`, care_receiver_data);
  return response;
}

export async function deactivateCareReceiver(care_receiver_pk) {
  const response = await post(
    `account/care-receivers/${care_receiver_pk}/deactivate/`,
    {},
  );
  return response;
}

export async function getActiveCareReceivers() {
  let response = await get(`account/care-receivers/?is_active=true`);
  return response;
}

export function checkForProfileCompletion(data, profile) {
  // Are first and last name present?
  let first_name_complete = data.first_name == "" ? false : true;
  let last_name_complete = data.last_name == "" ? false : true;
  // Is a primary email set?
  let primary_email_complete = data.email == "" ? false : true;
  // Is a phone number set?
  let phone_complete = data.phone == null ? false : true;
  // Is an address present in the profile?
  let address_set = profile.addresses.length == 0 ? false : true;

  // If all are true, profile is complete.
  return (
    first_name_complete &&
    last_name_complete &&
    primary_email_complete &&
    phone_complete &&
    address_set
  );
}
