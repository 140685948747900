<script>
import { Button } from "carbon-components-svelte";
import {
  Header,
  HeaderNav,
  HeaderUtilities,
  Content,
} from "carbon-components-svelte";
import Shell from "./Shell.svelte";
import google_login_logo from "./web_neutral_sq_SI@1x.png";

// This is a variable passed in the context from SvelteTemplateView
export let site_constants;

function onClickGoogleSignIn() {
  // Redirect to django allauth google login url
  window.location.href = site_constants.BASE_URL + "/accounts/google/login/";
}
</script>

<Shell
  renderSideNav="{false}"
  renderAvatar="{false}"
  site_constants="{site_constants}"
  fetchTermsAndConditions="{false}"
  userIsAvailable="{false}"
>
  <div class="loginContent">
    <h1>Login to Capacity</h1>
    <div class="loginBox">
      <h3 style="text-align: center;">
        Capacity only accepts Google Mail accounts for Beta
      </h3>
      <div style="text-align: center;">
        <Button kind="ghost" on:click="{onClickGoogleSignIn}">
          <span>
            <img src="{google_login_logo}" alt="Google Login" />
          </span>
        </Button>
      </div>
    </div>
  </div>
</Shell>

<style>
.loginContent {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.loginBox {
  border: 1px solid;
  margin: 16px;
}
</style>
