<script>
import { Button, TextInput } from "carbon-components-svelte";
import { createEventDispatcher } from "svelte";
import { del } from "../api.js";
import {
  patchUserData,
  patchCareReceiverData,
  postOtherProfilePhone,
} from "../api/account-api.js";
import AddAlt from "carbon-icons-svelte/lib/AddAlt.svelte";
import TrashCan from "carbon-icons-svelte/lib/TrashCan.svelte";
import UpdateNow from "carbon-icons-svelte/lib/UpdateNow.svelte";

export let data;
export let profile;

$: is_care_giver = "role" in profile ? profile.role === "CARE_GIVER" : false;

let dispatch = createEventDispatcher();

let primary_phone = data.phone;
let other_phone_numbers = [];
if ("other_phone_numbers" in profile) {
  other_phone_numbers = profile.other_phone_numbers;
}

let new_phone = "";
let primary_phone_invalid = false;
let new_phone_invalid = false;

async function updatePrimaryPhoneNumber() {
  try {
    // Patch the user data with any changes to first, preferred, or last name
    const response = is_care_giver
      ? await patchUserData(data.pk, { phone: primary_phone })
      : await patchCareReceiverData(data.pk, { phone: primary_phone });
    // patch successful
    if ("pk" in response) {
      // reload user
      dispatch("reload", { selected_tab: "PHONE" });
      is_care_giver
        ? console.log(`User updated their primary phone. user_pk:${data.pk}`)
        : console.log(
            `User updated care receiver phone. care_receiver_pk:${data.pk}`,
          );
    } else if ("error" in response) {
      primary_phone_invalid = true;
      console.log(response.error.phone);
      is_care_giver
        ? console.error("Error pushing user updates to server.")
        : console.error("Error pushing care receiver updates to server.");
    } else {
      console.log(response);
      is_care_giver
        ? console.error("Error pushing user updates to server.")
        : console.error("Error pushing care receiver updates to server.");
    }
  } catch (error) {
    // Catch fetch errors or errors in parsing the response
    console.error("Error updating primary phone:", error);
    return {
      success: false,
      error: "An error occurred while updating the primary phone number.",
    };
  }
}

async function addOtherPhoneNumber() {
  try {
    const response = await postOtherProfilePhone(data.pk, {
      phone: new_phone,
    });
    // patch successful
    if ("pk" in response) {
      // reload user
      dispatch("reload", { selected_tab: "PHONE" });
    } else if ("error" in response) {
      // phone number is invalid.
      new_phone_invalid = true;
      console.log(response.error.phone);
    } else {
      console.log(response);
    }
  } catch (error) {
    // Catch fetch errors or errors in parsing the response
    console.error("Error adding other phone:", error);
    return {
      success: false,
      error: "An error occurred while adding the other phone number.",
    };
  }
}

async function removeOtherPhoneNumber(_other_phone_pk) {
  try {
    console.log("Sending DELETE request");
    let response = await del(
      `account/users/${data.pk}/phone-numbers/${_other_phone_pk}/`,
    );
    console.log("Received response with status:", response.status);

    if (response.status === 204) {
      // Successfully deleted with no content in response
      // trigger a reload
      console.log("Processing 204 No Content response");
      // Reload profile, keep email tab selected.
      dispatch("reload", { selected_tab: "PHONE" });
      return { success: true };
    } else if (response.status === 400) {
      console.log("Processing 400 Bad Request response");
      return { success: false, error: "Bad request error" };
    } else {
      console.log("Processing unexpected response status");
      // Reload profile, keep email tab selected.
      dispatch("reload", { selected_tab: "PHONE" });
      return {
        success: false,
        error: `Unexpected response status: ${response.status}`,
      };
    }
  } catch (error) {
    // Catch fetch errors or errors in parsing the response
    console.error("Error deleting phone:", error);
    return {
      success: false,
      error: "An error occurred while deleting the phone number.",
    };
  }
}
</script>

<br />
<TextInput
  inline
  labelText="Primary Phone Number:"
  placeholder="Enter number with international dialing code..."
  invalid="{primary_phone_invalid}"
  invalidText="{'Invalid phone number. Include international dialing code, eg: +1 234 567 8989'}"
  bind:value="{primary_phone}"
  on:change="{updatePrimaryPhoneNumber}"
/>
<br />
-----------------
<br />

{#each other_phone_numbers as other_phone_number (other_phone_number.pk)}
  <TextInput
    inline
    readonly="{true}"
    labelText="Other phone"
    placeholder="Enter phone number with international dialing code, eg: +1 234 567 8901"
    value="{other_phone_number.phone}"
  />
  <br />
  <Button
    size="small"
    kind="danger-tertiary"
    icon="{TrashCan}"
    tooltipPosition="right"
    tooltipAlignment="end"
    iconDescription="Remove secondary phone"
    on:click="{() => {
      removeOtherPhoneNumber(other_phone_number.pk);
    }}"
  />
  <br />
{/each}

{#if is_care_giver}
  <TextInput
    inline
    labelText="New phone number"
    placeholder="Enter phone..."
    invalid="{new_phone_invalid}"
    invalidText="Enter phone number with international dialing code, eg: +1 234 567 8901"
    bind:value="{new_phone}"
  />
  <Button
    size="small"
    icon="{AddAlt}"
    tooltipPosition="right"
    tooltipAlignment="end"
    iconDescription="Add secondary phone number"
    on:click="{addOtherPhoneNumber}"
  />
{/if}
