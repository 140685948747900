// Enum for different page views
export const PageView = {
  DASHBOARD: "/account/dashboard/",
  CARERECEIVERS: "/account/carereceivers/",
  ACCOUNTS: "/account/financial-accounts/",
};

// Enum for roles
export const Roles = {
  CARE_GIVER: "Care Giver",
};

// Enum for notification preferences
export const NotificationPrefs = {
  NOTIFY_EMAIL: "Email",
  NOTIFY_PHONE: "Phone call",
  NOTIFY_SMS: "Text message (SMS)",
};

// Enum for gender identity
export const Gender = {
  MALE: "Male",
  FEMALE: "Female",
  NONBINARY: "Non-Binary",
};

// Enum for education level
export const Education = {
  UNKNOWN: "No educational background provided",
  EIGHTHGRADE: "Less than 8th grade",
  SOMEHIGHSCHOOL: "Some high school",
  HIGHSCHOOL: "High school graduate or GED",
  TRADESCHOOL: "Trade/technical/vocational training",
  SOMECOLLEGE: "Some college or Associate degree",
  BACHELOR: "Bachelor's degree",
  MASTER: "Master's degree",
  DOCTOR: "Doctorate/Professional degree",
};

// Enum for race / ethnicity
export const Ethnicity = {
  WHITE: "White non-Hispanic",
  LATINO: "Hispanic or Latino",
  BLACK: "Black or African American",
  ASIAN: "Asian",
  FIRSTPEOPLE: "Native American, American Indian, or Alaskan Native",
  PACIFICISLANDER: "Native Hawaiian or other Pacific Islander",
  OTHER: "Other",
  MIXED: "Mixed Race",
  NONEPROVIDED: "No Race/Ethnicity provided",
};

// Enum for care giver experience duration
export const CaregiverExperience = {
  MONTHS_LT_3: "Less than 3 months",
  MONTHS_3_6: "3 - 6 months",
  MONTHS_6_12: "6 - 12 months",
  YEARS_GT_1: "1-5 years",
  YEARS_GT_5: "More than 5 years",
};

// Enum for care giver comfort level
export const CaregiverComfortLevel = {
  VERY_UNCOMFORTABLE: "Very uncomfortable",
  MODERATELY_UNCOMFORTABLE: "Moderately uncomfortable",
  SOMEWHAT_COMFORTABLE: "Somewhat comfortable",
  MODERATELY_COMFORTABLE: "Moderately comfortable",
  VERY_COMFORTABLE: "Very comfortable",
};

// Enum for care receiver age range
export const AgeRange = {
  AGE_30_34: "30-34",
  AGE_35_39: "35-39",
  AGE_40_44: "40-44",
  AGE_45_49: "45-49",
  AGE_50_54: "50-54",
  AGE_55_59: "55-59",
  AGE_60_64: "60-64",
  AGE_65_69: "65-69",
  AGE_70_74: "70-74",
  AGE_75_79: "75-79",
  AGE_80_84: "80-84",
  AGE_85_89: "85-89",
  AGE_90_94: "90-94",
  AGE_95_99: "95-99",
  AGE_100_PLUS: "100+",
};

// Enum for care receiver relationship to user
export const RelationshipToCareReceiver = {
  FATHER: "Father",
  MOTHER: "Mother",
  SIBLING: "Sibling",
  IN_LAW: "Parent In-Law",
  EMPLOYER: "I am employed by Care Receiver",
  CLIENT: "Client",
  WARD: "Ward",
  OTHER: "Other",
};

// Enum for care receiver relationship duration
export const DurationOfCareRelationship = {
  MONTHS_LT_3: "<3 Months",
  MONTHS_3_6: "3-6 Months",
  MONTHS_6_12: "6-12 Months",
  YEARS_GT_1: "1-5 Years",
  YEARS_GT_5: ">5 Years",
};

// Enum for profile tabs in EditUserProfile.svelte
export const CareGiverProfileTabs = {
  PROFILE: 0,
  ADDRESS: 1,
  EMAIL: 2,
  PHONE: 3,
};

// Enum for profile tabs in EditUserProfile.svelte
export const CareReceiverProfileTabs = {
  PROFILE: 0,
  RELATIONSHIP: 1,
  ADDRESS: 2,
  EMAIL: 3,
  PHONE: 4,
};

// Enum for orientation of transactions
export const TransactionOrientations = {
  NONE: "Other",
  INCOME: "Income",
  SAVING_RECURRING: "Savings (Recurring)",
  SAVING_FLEXIBLE: "Savings (Flexible)",
  SAVING_UNFORSEEN: "Savings (Unforseen)",
  EXPENSE_RECURRING: "Expenses (Recurring)",
  EXPENSE_FLEXIBLE: "Expenses (Flexible)",
  EXPENSE_UNFORSEEN: "Expenses (Unforseen)",
  TRANSFER: "Bank Transfer",
};
