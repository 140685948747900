<script>
import {
  Button,
  Header,
  HeaderNav,
  HeaderAction,
  HeaderPanelDivider,
  HeaderPanelLinks,
  HeaderPanelLink,
  HeaderUtilities,
  SkipToContent,
  Content,
  Grid,
  Row,
  Column,
  Modal,
  SideNav,
  SideNavItems,
  SideNavMenu,
  SideNavLink,
} from "carbon-components-svelte";
import { PageView } from "./enums.js";
import { get } from "./api.js";
import { onMount, createEventDispatcher } from "svelte";
import UserAvatar from "carbon-icons-svelte/lib/UserAvatar.svelte";
import CustomBankIcon from "./icons/CustomBankIcon.svelte";
import CustomCaregiverIcon from "./icons/CustomCaregiverIcon.svelte";
import { expoIn } from "svelte/easing";
import TermsAndConditions from "./TermsAndConditions.svelte";
import EditUserProfile from "./Profile/EditUserProfile.svelte";
import { getUserInfo } from "./api/account-api.js";

export let user;

export let site_constants;

export let fetchTermsAndConditions = true;

export let userIsAvailable = true;

export let renderSideNav = true;

export let renderAvatar = true;

// Default function, can be overridden
export function onProfileClicked() {
  // Must reload user, otherwise whatever info was present when profile was first mounted will persist.
  dispatch("reloadUserInfo");
  isProfileOpen = true;
}

// Default function, can be overridden
export function onTermsAndConditionsClicked() {
  isTermsAndConditionsOpen = true;
}

// Default function, can be overridden
export function onSettingsClicked() {
  console.log("TODO: Implement settings");
}

// Default function, can be overridden
function onSidebarClicked(page) {
  //If clicked page is same as current, reload.
  if (window.location.href == site_constants.BASE_URL + page) {
    window.location.reload(true);
  }
  //If link is for new page, route to page.
  else {
    window.location.href = site_constants.BASE_URL + page;
  }
}

export function onLogoutClicked() {
  window.location.href = site_constants.BASE_URL + "/accounts/logout";
}

let dispatch = createEventDispatcher();

let isSideNavOpen = false;

let isHeaderOpen = false;

let isProfileOpen = false;

let isTermsAndConditionsOpen = false;

// Function to reload user info
let userReload = null;

let isLoading = false;

async function reloadUserInfo() {
  isLoading = true;
  userReload = await getUserInfo(user.pk);
  isLoading = false;
}

function handlePatchCompleted() {
  dispatch("reloadUserInfo");
  reloadUserInfo();
}

function handleCloseProfile() {
  isProfileOpen = false;
}

let user_menu_transitions = {
  text: "Custom (duration: 250ms, delay: 50ms, easing: expoIn)",
  value: { duration: 250, delay: 50, easing: expoIn },
};

onMount(async () => {
  reloadUserInfo();
});
</script>

<Header
  company="MoneyPositive"
  platformName="Capacity"
  bind:isSideNavOpen="{isSideNavOpen}"
>
  <HeaderUtilities>
    <HeaderNav>
      {#if renderAvatar}
        <HeaderAction
          icon="{UserAvatar}"
          bind:isOpen="{isHeaderOpen}"
          transition="{user_menu_transitions.value}"
        >
          <HeaderPanelLinks>
            <HeaderPanelDivider>
              {#if userIsAvailable}
                {user.nickname == "" ? user.first_name : user.nickname}
                {user.last_name}
              {/if}
            </HeaderPanelDivider>
            <HeaderPanelLink on:click="{onProfileClicked}">
              My Profile
            </HeaderPanelLink>
            <HeaderPanelLink on:click="{onTermsAndConditionsClicked}">
              Terms and Conditions
            </HeaderPanelLink>
            <HeaderPanelLink on:click="{onSettingsClicked}">
              Settings
            </HeaderPanelLink>
            <HeaderPanelDivider />
            <HeaderPanelLink on:click="{onLogoutClicked}">
              Logout
            </HeaderPanelLink>
          </HeaderPanelLinks>
        </HeaderAction>
      {/if}
    </HeaderNav>
  </HeaderUtilities>
</Header>

{#if renderSideNav}
  <SideNav bind:isOpen="{isSideNavOpen}" rail fixed>
    <SideNavItems>
      <SideNavLink
        icon="{CustomCaregiverIcon}"
        text="Care Receivers"
        on:click="{() => onSidebarClicked(PageView.CARERECEIVERS)}"
      />
      <SideNavLink
        icon="{CustomBankIcon}"
        text="Accounts"
        on:click="{() => onSidebarClicked(PageView.ACCOUNTS)}"
      />
    </SideNavItems>
  </SideNav>
{/if}

<Content>
  <slot />
</Content>

{#if fetchTermsAndConditions}
  <TermsAndConditions
    bind:open="{isTermsAndConditionsOpen}"
    view_only="{true}"
    base_url="{site_constants.BASE_URL}"
    user_pk="{user.pk}"
  />
{/if}

{#if userIsAvailable && userReload}
  <!--This await is used to force a reload of the user info when the button is clicked, after any updates elsewhere. Could be neater-->
  {#if isLoading}
    <p>Loading...</p>
  {:else}
    <EditUserProfile
      open="{isProfileOpen}"
      user_pk="{userReload.pk}"
      heading="{`Your Profile`}"
      on:patchCompleted="{handlePatchCompleted}"
      on:profileClosed="{handleCloseProfile}"
    />
  {/if}
{/if}

<style>
</style>
