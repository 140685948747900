<script>
import { Button, TextInput } from "carbon-components-svelte";
import { createEventDispatcher } from "svelte";
import { del } from "../api.js";
import {
  patchUserData,
  postOtherProfileEmail,
  patchCareReceiverData,
} from "../api/account-api.js";
import AddAlt from "carbon-icons-svelte/lib/AddAlt.svelte";
import UpdateNow from "carbon-icons-svelte/lib/UpdateNow.svelte";
import TrashCan from "carbon-icons-svelte/lib/TrashCan.svelte";

export let data;
export let profile;

$: is_care_giver = "role" in profile ? profile.role === "CARE_GIVER" : false;

let dispatch = createEventDispatcher();

let primary_email = data.email;

let other_emails = [];
if ("other_emails" in profile) {
  other_emails = profile.other_emails;
}

let new_email = "";
let primary_email_invalid = false;
let new_email_invalid = false;

async function updatePrimaryEmail() {
  // Patch the user data with any changes to first, preferred, or last name
  let patch_data = { email: primary_email };
  const response = is_care_giver
    ? await patchUserData(data.pk, patch_data)
    : await patchCareReceiverData(data.pk, patch_data);

  // patch successful
  if ("pk" in response) {
    // reload user
    dispatch("reload", { selected_tab: "EMAIL" });
    is_care_giver
      ? console.log(`User updated their primary email. user_pk:${data.pk}`)
      : console.log(
          `User updated care receiver email. care_receiver_pk:${data.pk}`,
        );
  } else if ("error" in response) {
    primary_email_invalid = true;
    console.log(response.error);
    is_care_giver
      ? console.error("Error pushing user email to server.")
      : console.error("Error pushing care receiver updates to server.");
  } else {
    console.log(response);
    is_care_giver
      ? console.error("Error pushing user email to server.")
      : console.error("Error pushing care receiver email to server.");
  }
}

async function addOtherEmail() {
  const response = await postOtherProfileEmail(data.pk, {
    email: new_email,
  });
  // patch successful
  if ("pk" in response) {
    // reload user
    dispatch("reload", { selected_tab: "EMAIL" });
    console.log(`User updated their data. user_pk:${data.pk}`);
  } else if ("error" in response) {
    new_email_invalid = true;
    console.log(response.error);
    console.error("Error pushing user updates to server.");
  } else {
    console.log(response);
    console.error("Error pushing user updates to server.");
  }
}

async function removeOtherEmail(_other_email_pk) {
  try {
    console.log("Sending DELETE request");
    let response = await del(
      `account/users/${data.pk}/emails/${_other_email_pk}/`,
    );
    console.log("Received response with status:", response.status);

    if (response.status === 204) {
      // Successfully deleted with no content in response
      // trigger a reload
      console.log("Processing 204 No Content response");
      // Reload profile, keep email tab selected.
      dispatch("reload", { selected_tab: "EMAIL" });
      return { success: true };
    } else if (response.status === 400) {
      console.log("Processing 400 Bad Request response");
      return { success: false, error: "Bad request error" };
    } else {
      console.log("Processing unexpected response status");
      // Reload profile, keep email tab selected.
      dispatch("reload", { selected_tab: "EMAIL" });
      return {
        success: false,
        error: `Unexpected response status: ${response.status}`,
      };
    }
  } catch (error) {
    // Catch fetch errors or errors in parsing the response
    console.error("Error deleting email:", error);
    return {
      success: false,
      error: "An error occurred while deleting the email.",
    };
  }
}
</script>

<br />
{#if is_care_giver}
  <TextInput
    inline
    readonly="{true}"
    labelText="Primary email"
    placeholder="Enter email..."
    bind:value="{primary_email}"
  />
  <br />
{:else}
  <TextInput
    inline
    labelText="Primary email"
    placeholder="Enter email..."
    invalid="{primary_email_invalid}"
    invalidText="Email is formatted incorrectly, example: test@test.com"
    bind:value="{primary_email}"
    on:change="{updatePrimaryEmail}"
  />
{/if}
-----------------
<br />

{#each other_emails as other_email (other_email.pk)}
  <TextInput
    inline
    readonly="{true}"
    labelText="Other email"
    placeholder="Enter email..."
    invalid="{new_email_invalid}"
    invalidText="Email is formatted incorrectly, example: test@test.com"
    value="{other_email.email}"
  />
  <Button
    size="small"
    kind="danger-tertiary"
    tooltipPosition="right"
    tooltipAlignment="end"
    iconDescription="Remove secondary email"
    icon="{TrashCan}"
    on:click="{() => {
      removeOtherEmail(other_email.pk);
    }}"
  />
  <br />
{/each}

{#if "other_emails" in profile}
  <TextInput
    inline
    labelText="New email"
    placeholder="Enter email..."
    invalid="{new_email_invalid}"
    invalidText="{`Email format is invalid`}"
    bind:value="{new_email}"
  />
  <br />
  <Button
    size="small"
    icon="{AddAlt}"
    tooltipPosition="right"
    tooltipAlignment="end"
    iconDescription="Add other email"
    on:click="{addOtherEmail}"
  />
{/if}
