<script>
import {
  Select,
  SelectItem,
  TextInput,
  Checkbox,
  Button,
} from "carbon-components-svelte";
import { createEventDispatcher } from "svelte";
import { get } from "../api.js";
import { patchProfileAddress, postProfileAddress } from "../api/account-api.js";
import AddAlt from "carbon-icons-svelte/lib/AddAlt.svelte";
import UpdateNow from "carbon-icons-svelte/lib/UpdateNow.svelte";
import CloseLarge from "carbon-icons-svelte/lib/CloseLarge.svelte";

export let address = {};
export let countries;
export let user_pk;
export let care_receiver_pk;

let dispatch = createEventDispatcher();

let new_address = false;
if (Object.keys(address).length == 0) {
  new_address = true;
}

let street1 = "";
let street2 = "";
let locality_name = "";
let postal_code = "";
let state_code = "";
let country_code = "US";
let primary = false;

let invalidTextStreet1 = "Street address is required";
let invalidTextStreet2 = "Optional";
let invalidTextPostalCode = "Postal / ZIP code is required";
let invalidTextLocality = "City / locality is required";
let invalidTextState = "State / Region is required";

// populate fields if available
if ("street1" in address) {
  street1 = address.street1;
}

if ("street2" in address) {
  street2 = address.street2;
}

if ("locality" in address) {
  locality_name = address.locality.name;
  postal_code = address.locality.postal_code;
  state_code = address.locality.state.code;
  country_code = address.locality.state.country.code;
}

if ("primary" in address) {
  primary = address.primary;
}

$: street1_required = street1 == "";
$: locality_name_required = locality_name == "";
$: postal_code_required = postal_code == "";
$: state_code_required = state_code == "";
let street2_required = false; // Street 2 is optional so just define as false. Can error if incorrect value is entered and patched.

$: console.log(street1_required);
$: console.log(locality_name_required);
$: console.log(postal_code_required);
$: console.log(state_code_required);

$: address_invalid =
  street1_required ||
  street2_required ||
  locality_name_required ||
  postal_code_required ||
  state_code_required;
$: console.log(address_invalid);
let states_promise = new Promise(() => {});

getStatesPromise();

$: country_code, getStatesPromise();

async function getStatesPromise() {
  states_promise = await get(`account/states/?country__code=${country_code}`);
}

function createAddressData() {
  let address_data = address; // empty in this case.
  address_data.street1 = street1;
  address_data.street2 = street2;
  address_data.locality = { state: { country: {} } }; // Clear field, in case state / country names change.
  address_data.locality.name = locality_name;
  address_data.locality.postal_code = postal_code;
  address_data.locality.state.code = state_code;
  address_data.locality.state.country.code = country_code;
  address_data.primary = primary;
  return address_data;
}

function handleAddressError(_response) {
  // update error fields.
  if ("street1" in _response.error) {
    street1_required = true;
    invalidTextStreet1 = _response.error.street1;
  }
  if ("street2" in _response.error) {
    street2_required = true;
    invalidTextStreet2 = _response.error.street2;
  }
  if ("locality" in _response.error) {
    if ("name" in _response.error.locality) {
      locality_name_required = true;
      invalidTextLocality = _response.error.locality.name;
    }
    if ("postal_code" in _response.error.locality) {
      postal_code_required = true;
      invalidTextPostalCode = _response.error.locality.postal_code;
    }
    if ("state" in _response.error.locality) {
      if ("code" in _response.error.locality.state) {
        state_code_required = true;
        invalidTextState = _response.error.locality.state.code;
      }
    }
  }
}

async function handleUpdateAddress() {
  // Will use the existing address as a basis, so will contain pk.
  let address_to_patch = createAddressData();
  // patch the data
  try {
    let response = await patchProfileAddress(
      address_to_patch.pk,
      address_to_patch,
    );
    if ("pk" in response) {
      // patch is successful
      dispatch("reload", { selected_tab: "ADDRESS" });
    }
    // Populate error fields.
    else if ("error" in response) {
      handleAddressError(response);
    } else {
      // Catch fetch errors or errors in parsing the response
      console.error("Error patching address:", response);
      return {
        success: false,
        error: "An error occurred while patching the address.",
      };
    }
  } catch (error) {
    // Catch fetch errors or errors in parsing the response
    console.error("Error patching address:", error);
    return {
      success: false,
      error: "An error occurred while patching the address.",
    };
  }
}

async function handleAddAddress() {
  let address_to_add = createAddressData();
  // Set pk correctly
  if (user_pk !== "") {
    address_to_add.user_pk = user_pk;
  }
  if (care_receiver_pk !== "") {
    address_to_add.care_receiver_pk = care_receiver_pk;
  }

  try {
    let response = await postProfileAddress(address_to_add);
    if ("pk" in response) {
      // patch is successful
      dispatch("reload", { selected_tab: "ADDRESS" });
    }
    // Populate error fields.
    else if ("error" in response) {
      handleAddressError(response);
    } else {
      // Catch fetch errors or errors in parsing the response
      console.error("Error posting address:", response);
      return {
        success: false,
        error: "An error occurred while posting the address.",
      };
    }
  } catch (error) {
    // Catch fetch errors or errors in parsing the response
    console.error("Error posting address:", error);
    return {
      success: false,
      error: "An error occurred while posting the address.",
    };
  }
}
</script>

<TextInput
  inline
  labelText="Street 1:"
  placeholder="Enter street address..."
  invalid="{street1_required}"
  invalidText="{invalidTextStreet1}"
  bind:value="{street1}"
/>
<br />
<TextInput
  inline
  labelText="Street 2:"
  placeholder="Enter street address..."
  invalid="{street2_required}"
  invalidText="{invalidTextStreet2}"
  bind:value="{street2}"
/>
<br />
<TextInput
  inline
  labelText="City:"
  placeholder="Enter city or locality..."
  invalid="{locality_name_required}"
  invalidText="{invalidTextLocality}"
  bind:value="{locality_name}"
/>
<br />
<TextInput
  inline
  labelText="Postal code:"
  placeholder="Enter postal or ZIP code..."
  invalid="{postal_code_required}"
  invalidText="{invalidTextPostalCode}"
  bind:value="{postal_code}"
/>
<br />
<Select
  inline
  labelText="State / Region:"
  invalid="{state_code_required}"
  invalidText="{invalidTextState}"
  bind:selected="{state_code}"
>
  <SelectItem value="" text="--" />
  {#await states_promise then states}
    {#each states as state}
      <SelectItem value="{state.code}" text="{state.name}" />
    {/each}
  {/await}
</Select>
<br />
<Select inline labelText="Country:" bind:selected="{country_code}">
  {#each countries as country}
    <SelectItem value="{country.code}" text="{country.name}" />
  {/each}
</Select>

<Checkbox labelText="Primary" bind:checked="{primary}" />

{#if new_address}
  <Button
    disabled="{address_invalid}"
    size="small"
    icon="{AddAlt}"
    iconDescription="Add"
    on:click="{handleAddAddress}"
  />
{:else}
  <Button
    disabled="{address_invalid}"
    size="small"
    icon="{UpdateNow}"
    iconDescription="Update"
    on:click="{handleUpdateAddress}"
  />
{/if}
<Button
  size="small"
  icon="{CloseLarge}"
  iconDescription="Cancel"
  on:click="{() => dispatch('cancelEditAddress')}"
/>
