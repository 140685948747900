<script>
import { Button } from "carbon-components-svelte";
import { createEventDispatcher } from "svelte";
import { del } from "../api.js";
import TrashCan from "carbon-icons-svelte/lib/TrashCan.svelte";
import Edit from "carbon-icons-svelte/lib/Edit.svelte";

export let address;

let dispatch = createEventDispatcher();

async function handleRemoveAddress() {
  try {
    console.log("Sending DELETE request");
    let response = await del(`account/mailing-addresses/${address.pk}/`);
    console.log("Received response with status:", response.status);

    if (response.status === 204) {
      // Successfully deleted with no content in response
      // trigger a reload
      console.log("Processing 204 No Content response");
      dispatch("reload", { selected_tab: "ADDRESS" });
      // keep address tab selected.
      return { success: true };
    } else if (response.status === 400) {
      console.log("Processing 400 Bad Request response");
      return { success: false, error: "Bad request error" };
    } else {
      console.log("Processing unexpected response status");
      // keep address tab selected.
      dispatch("reload", { selected_tab: "ADDRESS" });
      return {
        success: false,
        error: `Unexpected response status: ${response.status}`,
      };
    }
  } catch (error) {
    // Catch fetch errors or errors in parsing the response
    console.error("Error deleting address:", error);
    return {
      success: false,
      error: "An error occurred while deleting the address.",
    };
  }
}
</script>

{address.street1}{address.street2 == null ? "" : `, ${address.street2}`}<br />
{address.locality.name}, {address.locality.state.code}
{address.locality.postal_code} <br />
{address.locality.state.country.name} <br />

<Button
  size="small"
  iconDescription="Edit"
  icon="{Edit}"
  on:click="{() => {
    dispatch('editAddress', { address: address });
  }}"
/>
<Button
  size="small"
  kind="danger-tertiary"
  iconDescription="Remove"
  icon="{TrashCan}"
  on:click="{handleRemoveAddress}"
/>
