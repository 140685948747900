<script>
import {
  TextInput,
  Select,
  SelectItem,
  DatePicker,
  DatePickerInput,
} from "carbon-components-svelte";
import { createEventDispatcher, onMount } from "svelte";
import {
  Gender,
  Education,
  Ethnicity,
  CaregiverExperience,
  CaregiverComfortLevel,
  NotificationPrefs,
} from "../enums.js";
import {
  patchUserData,
  patchUserProfile,
  checkForProfileCompletion,
} from "../api/account-api.js";

export let data;
export let profile;

let dispatch = createEventDispatcher();

// Not reactive, only defined when component is first loaded
let first_name = data.first_name;
let last_name = data.last_name;
let preferred_name = data.nickname;

let notification_preferences = profile.notification_preferences;
let dob = profile.dob;
let gender = profile.gender;
let education_level = profile.education_level;
let ethnicity = profile.ethnicity;
let care_giver_experience = profile.care_giver_experience;
let financial_care_comfort_level = profile.financial_care_comfort_level;
// let picture = profile.picture;

// Determine whether required fields are good
let first_name_required = false;
let last_name_required = false;

// Update requirements on field value change
$: updateFirstNameRequired(first_name);
$: updateLastNameRequired(last_name);

function toReadableDate(_date) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let date_js = new Date(_date);
  return `${date_js.getDate()} ${
    months[date_js.getMonth()]
  }, ${date_js.getFullYear()}`;
}

function updateFirstNameRequired(_first_name) {
  first_name_required = _first_name == "" ? true : false;
}

function updateLastNameRequired(_last_name) {
  last_name_required = _last_name == "" ? true : false;
}

async function handleUpdate() {
  // only patch the profile if there is data to be patched:
  let profile_patch_data = {};
  if (dob !== null && dob !== "") {
    profile_patch_data.dob = dob;
  }
  if (gender !== null && gender !== "") {
    profile_patch_data.gender = gender;
  }
  if (education_level !== null && education_level !== "") {
    profile_patch_data.education_level = education_level;
  }
  if (ethnicity !== null && ethnicity !== "") {
    profile_patch_data.ethnicity = ethnicity;
  }
  if (care_giver_experience !== null && care_giver_experience !== "") {
    profile_patch_data.care_giver_experience = care_giver_experience;
  }
  if (
    financial_care_comfort_level !== null &&
    financial_care_comfort_level !== ""
  ) {
    profile_patch_data.financial_care_comfort_level =
      financial_care_comfort_level;
  }
  if (notification_preferences !== null && notification_preferences !== "") {
    profile_patch_data.notification_preferences = notification_preferences;
  }
  const profile_response = await patchUserProfile(data.pk, profile_patch_data);

  // patch successful
  if ("pk" in profile_response) {
    // Check for profile completion
    dispatch("reload", { selected_tab: "PROFILE" });
    //open = false; // This will close the modal
    console.log(`User patched their profile. user_pk:${data.pk}`);
  } else if ("error" in profile_response) {
    console.log(profile_response);
    console.error("Error pushing user profile updates to server.");
  } else {
    console.log(profile_response);
    console.error("Error pushing user profile updates to server.");
  }

  // Patch the user data with any changes to first, preferred, or last name
  let patch_data = {
    first_name: first_name,
    last_name: last_name,
    nickname: preferred_name,
  };

  const response = await patchUserData(data.pk, patch_data);
  // patch successful
  if ("pk" in response) {
    // reload user
    dispatch("reload", { selected_tab: "PROFILE" });
    console.log(`User updated their data. user_pk:${data.pk}`);
  } else if ("error" in response) {
    console.log(response.error);
    console.error("Error pushing user updates to server.");
  } else {
    console.log(response);
    console.error("Error pushing user updates to server.");
  }
}

onMount(() => {
  // Check for profile completion on mount (i.e. after any reload of data) - required for Dashboard.
  if (checkForProfileCompletion(data, profile)) {
    dispatch("profileCompleted");
  }
});
</script>

<TextInput
  inline
  labelText="First name"
  placeholder="Enter first name..."
  invalid="{first_name_required}"
  invalidText="First name is required"
  bind:value="{first_name}"
  on:change="{handleUpdate}"
/>

<TextInput
  inline
  labelText="Last name"
  placeholder="Enter last name..."
  invalid="{last_name_required}"
  invalidText="Last name is required"
  bind:value="{last_name}"
  on:change="{handleUpdate}"
/>

<TextInput
  inline
  labelText="Preferred name"
  placeholder="Enter preferred name..."
  bind:value="{preferred_name}"
  on:change="{handleUpdate}"
/>

<!--Date of Birth-->
<DatePicker
  datePickerType="single"
  dateFormat="Y-m-d"
  value="{dob}"
  on:change="{({ target }) => {
    dob = target.value;
    handleUpdate();
  }}"
>
  <DatePickerInput labelText="Date of Birth" placeholder="mm/dd/yyyy" />
</DatePicker>

<!--Gender-->
<Select
  inline
  labelText="Gender"
  selected="{gender}"
  on:change="{({ target }) => {
    gender = target.value;
    handleUpdate();
  }}"
>
  {#if gender === null || gender === ""}
    <SelectItem value="{''}" text="{''}" />
  {/if}
  {#each Object.keys(Gender) as gen}
    <SelectItem value="{gen}" text="{Gender[gen]}" />
  {/each}
</Select>

<!--Ethnicity-->
<Select
  inline
  labelText="Ethnicity"
  selected="{ethnicity}"
  on:change="{({ target }) => {
    ethnicity = target.value;
    handleUpdate();
  }}"
>
  {#if ethnicity === null || ethnicity === ""}
    <SelectItem value="{''}" text="{''}" />
  {/if}
  {#each Object.keys(Ethnicity) as ethn}
    <SelectItem value="{ethn}" text="{Ethnicity[ethn]}" />
  {/each}
</Select>

<!--Education-->
<Select
  inline
  labelText="Education"
  bind:selected="{education_level}"
  on:change="{({ target }) => {
    education_level = target.value;
    handleUpdate();
  }}"
>
  {#if education_level === null || education_level === ""}
    <SelectItem value="{''}" text="{''}" />
  {/if}
  {#each Object.keys(Education) as edu}
    <SelectItem value="{edu}" text="{Education[edu]}" />
  {/each}
</Select>

<!--Experience-->
<Select
  inline
  labelText="Financial care giving experience"
  selected="{care_giver_experience}"
  on:change="{({ target }) => {
    care_giver_experience = target.value;
    handleUpdate();
  }}"
>
  {#if care_giver_experience === null || care_giver_experience === ""}
    <SelectItem value="{''}" text="{''}" />
  {/if}
  {#each Object.keys(CaregiverExperience) as exp}
    <SelectItem value="{exp}" text="{CaregiverExperience[exp]}" />
  {/each}
</Select>

<!--Comfort Level-->
<Select
  inline
  labelText="Financial Comfort Level"
  selected="{financial_care_comfort_level}"
  on:change="{({ target }) => {
    financial_care_comfort_level = target.value;
    handleUpdate();
  }}"
>
  {#if financial_care_comfort_level === null || financial_care_comfort_level === ""}
    <SelectItem value="{''}" text="{''}" />
  {/if}
  {#each Object.keys(CaregiverComfortLevel) as comf}
    <SelectItem value="{comf}" text="{CaregiverComfortLevel[comf]}" />
  {/each}
</Select>

<!--Notification Preferences-->
<Select
  inline
  labelText="Notification Preference"
  selected="{notification_preferences}"
  on:change="{({ target }) => {
    notification_preferences = target.value;
    handleUpdate();
  }}"
>
  {#if notification_preferences === null || notification_preferences === ""}
    <SelectItem value="{''}" text="{''}" />
  {/if}
  {#each Object.keys(NotificationPrefs) as np}
    <SelectItem value="{np}" text="{NotificationPrefs[np]}" />
  {/each}
</Select>
