<script>
import { Modal, Tabs, Tab, TabContent } from "carbon-components-svelte";
import UserProfileFields from "./UserProfileFields.svelte";
import Addresses from "./Addresses.svelte";
import Emails from "./Emails.svelte";
import Phones from "./Phones.svelte";
import { get } from "../api.js";
import { createEventDispatcher, onMount } from "svelte";
import { CareGiverProfileTabs } from "../enums";

export let open = false; // Opens the modal
export let user_pk; // The user's primary key
export let heading; // Heading for the modal, depends on where modal is opened from.
export let edit_on_first_open = false; // if true, will open in editing on mount.

let open_in_editing = edit_on_first_open;
let load_counter = 0;

// After profile page has been reloaded for the first time, disable opening in editing.
$: if (load_counter > 0) {
  open_in_editing = false;
}

// Selected tab
let selected_tab = CareGiverProfileTabs.PROFILE;

let user_promise = loadUserPromise();

let dispatch = createEventDispatcher();

function handleClose() {
  open = false;
  selected_tab = CareGiverProfileTabs.PROFILE;
  user_promise = loadUserPromise(); // reloading refreshes all fields for when the modal is next opened.
  dispatch("profileClosed");
}

async function loadUserPromise() {
  return await get(`account/users/${user_pk}/`);
}

async function loadUserProfilePromise() {
  return await get(`account/users/${user_pk}/profile/`);
}

onMount(() => {
  user_promise = loadUserPromise();
});
</script>

<Modal
  bind:open="{open}"
  modalHeading="{heading}"
  passiveModal
  preventCloseOnClickOutside="{true}"
  hasScrollingContent="{true}"
  on:close="{handleClose}"
>
  <div style="width: 45vw; height: 60vh;">
    {#await user_promise}
      ... Loading
    {:then user_data}
      {#await loadUserProfilePromise(user_data.profile_pk)}
        ... Loading
      {:then user_profile}
        <Tabs type="container" bind:selected="{selected_tab}">
          <Tab label="Profile" />
          <Tab label="Mailing Addresses" />
          <Tab label="Email Addresses" />
          <Tab label="Phone Numbers" />
          <svelte:fragment slot="content">
            <TabContent style="bx--modal-content">
              <UserProfileFields
                data="{user_data}"
                profile="{user_profile}"
                on:reload="{(e) => {
                  user_promise = loadUserPromise();
                  selected_tab = CareGiverProfileTabs[e.detail.selected_tab];
                  load_counter++;
                }}"
                on:profileCompleted
              />
            </TabContent>
            <TabContent style="bx--modal-content">
              <Addresses
                addresses="{user_profile.addresses}"
                user_pk="{user_pk}"
                on:reload="{(e) => {
                  user_promise = loadUserPromise();
                  selected_tab = CareGiverProfileTabs[e.detail.selected_tab];
                }}"
              />
            </TabContent>
            <TabContent style="bx--modal-content">
              <Emails
                data="{user_data}"
                profile="{user_profile}"
                on:reload="{(e) => {
                  user_promise = loadUserPromise();
                  selected_tab = CareGiverProfileTabs[e.detail.selected_tab];
                }}"
              />
            </TabContent>
            <TabContent style="bx--modal-content">
              <Phones
                data="{user_data}"
                profile="{user_profile}"
                on:reload="{(e) => {
                  user_promise = loadUserPromise();
                  selected_tab = CareGiverProfileTabs[e.detail.selected_tab];
                }}"
              />
            </TabContent>
          </svelte:fragment>
        </Tabs>
      {/await}
    {/await}
  </div>
</Modal>
