<script>
import { Button, Modal } from "carbon-components-svelte";
import { onMount } from "svelte";
import { getUserInfo, postTermsAndConditionsUpdate } from "./api/account-api";

export let open = false; // Opens the modal
export let view_only = true; // If the modal is view only. There is no "Accept" button in this case.
export let base_url; // The base url of the site, used for redirect when canceling the T & C
export let user_pk; // The user's primary key

let accepted = false;

// Reactively updated the displayed terms and conditions as needed
$: terms_and_conditions = "Loading Terms and Conditions";

let terms_and_conditions_pk = null;

// Gets the terms and conditions from the server
async function getTermsAndConditions() {
  try {
    const response = await fetch(`${base_url}/api/latest-terms-and-conditions`);
    if (response.ok) {
      const data = await response.json();
      terms_and_conditions = data.content;
      terms_and_conditions_pk = data.pk;
    } else {
      console.error("Error fetching terms and conditions:", response.status);
      terms_and_conditions = "Failed to load Terms and Conditions.";
    }
  } catch (error) {
    console.error("Network error:", error);
    terms_and_conditions = "Failed to load Terms and Conditions.";
  }
}

// Push to the server that the user has accepted, otherwise don't update.
async function pushConfirmedTermsAndConditions() {
  let user_from_server = await getUserInfo(user_pk);

  let lifecycle_pk = user_from_server.lifecycle_pk;

  if (terms_and_conditions_pk == null) {
    console.log("Error: terms_and_conditions_pk is null");
    return;
  }

  try {
    await postTermsAndConditionsUpdate(
      user_from_server.pk,
      terms_and_conditions_pk,
    );
  } catch (error) {
    console.log(error);
  }
}

function handlePrimaryButtonClick() {
  pushConfirmedTermsAndConditions()
    .then(() => {
      accepted = true; // Mark the terms as accepted
      open = false; // This will close the modal
      console.log(
        `User accepted terms and conditions. user_pk:${user_pk} terms_pk:${terms_and_conditions_pk}`,
      );
    })
    .catch(() => {
      console.error(
        "Error pushing confirmation of terms and conditions to server.",
      );
    });
}

function handleClose() {
  if (accepted) {
    window.location.reload(true);
  } else {
    window.location.href = `${base_url}/account/logout`;
  }
}

onMount(async () => {
  await getTermsAndConditions();
});
</script>

{#if view_only}
  <Modal
    bind:open="{open}"
    modalHeading="Terms and Conditions"
    primaryButtonText="Close"
    hasScrollingContent="{true}"
    passiveModal="{true}"
  >
    <p>{terms_and_conditions}</p>
  </Modal>
{:else}
  <Modal
    bind:open="{open}"
    modalHeading="Terms and Conditions"
    primaryButtonText="Accept"
    preventCloseOnClickOutside="{true}"
    shouldSubmitOnEnter="{true}"
    hasScrollingContent="{true}"
    on:close="{handleClose}"
    on:click:button--primary="{handlePrimaryButtonClick}"
  >
    <p>{terms_and_conditions}</p>
  </Modal>
{/if}
