/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Authentication into the API involves a little sleight of hand. Since
 * the backend is delivering this Svelte app within a user's session, the
 * session cookie is used for GET requests. Extra validation is performed
 * on POST requests to prevent against Cross-Site Request Forgery. The
 * `getCookie` method extracts the CSRF token from the session cookie.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const base = "/api/";

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

async function send({ method, path, data }) {
  const fetch = window.fetch;
  const opts = { method, headers: {} };
  if (data) {
    opts.headers["Content-Type"] = "application/json";
    opts.headers["X-CSRFToken"] = getCookie("csrftoken");
    opts.body = JSON.stringify(data);
  }
  let url = `${base}${path}`;
  return await fetch(url, opts)
    .then((r) => {
      if (!r.ok) {
        throw r;
      }
      // Check if the response has content
      if (r.status === 204) {
        return r; // Return an empty object for 204 No Content responses
      } else {
        return r.json(); // Only parse as JSON if the response is not 204
      }
    })
    .catch((e) => {
      // Handle errors where the response might not be in JSON format
      if (e.headers.get("Content-Type")?.includes("application/json")) {
        return e.json().then((body) => {
          return {
            error: body,
            status: e.status,
          };
        });
      } else {
        return Promise.reject({
          error: "Error processing response",
          status: e.status,
        });
      }
    });
}

export function get(path) {
  return send({ method: "GET", path });
}

export function del(path) {
  return send({ method: "DELETE", path, data: {} });
}

export function post(path, data) {
  return send({ method: "POST", path, data });
}

export function put(path, data) {
  return send({ method: "PUT", path, data });
}

export function patch(path, data) {
  return send({ method: "PATCH", path, data });
}
