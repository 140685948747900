<script>
import DisplayAddress from "./DisplayAddress.svelte";
import EditAddress from "./EditAddress.svelte";
import { Button } from "carbon-components-svelte";
import { get } from "../api.js";
import AddAlt from "carbon-icons-svelte/lib/AddAlt.svelte";

export let addresses;
export let user_pk = "";
export let care_receiver_pk = "";

let adding_address = false;
let editing_address = false;
let address_to_edit = {};

async function getCountries() {
  let _countries = await get(`account/countries/`);
  return _countries;
}
</script>

{#await getCountries() then countries}
  {#if editing_address}
    <EditAddress
      countries="{countries}"
      address="{address_to_edit}"
      user_pk="{user_pk}"
      care_receiver_pk="{care_receiver_pk}"
      on:cancelEditAddress="{() => {
        editing_address = false;
        address_to_edit = {};
      }}"
      on:reload
    />
  {:else if adding_address == false}
    {#each addresses as address}
      {#if address.primary === true}
        Primary: <br />
        <DisplayAddress
          address="{address}"
          on:editAddress="{(e) => {
            editing_address = true;
            address_to_edit = e.detail.address;
          }}"
          on:reload
        />
      {/if}
      <br />
      --------------
      <br />
    {/each}
    {#each addresses as address}
      {#if address.primary !== true}
        <DisplayAddress
          address="{address}"
          on:editAddress="{(e) => {
            editing_address = true;
            address_to_edit = e.detail.address;
          }}"
          on:reload
        />
      {/if}
      <br />
    {/each}
  {/if}

  {#if adding_address}
    <EditAddress
      countries="{countries}"
      user_pk="{user_pk}"
      care_receiver_pk="{care_receiver_pk}"
      on:reload
      on:cancelEditAddress="{() => {
        adding_address = false;
      }}"
    />
    <br />
  {:else if editing_address == false}
    <br />
    <Button
      size="small"
      icon="{AddAlt}"
      tooltipPosition="right"
      tooltipAlignment="end"
      iconDescription="Add address"
      on:click="{() => {
        adding_address = true;
      }}"
    />
  {/if}
{/await}
